import { GET_NOTIFICATIONS_DATA } from '../actions'
import { IBannerReducer } from '../interface'

interface IActionNotifications {
  type: string
  payload: IBannerReducer[]
}

export const notificationsReducer = (state = null, action: IActionNotifications) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_DATA:
      return action.payload
    default:
      return state
  }
}
