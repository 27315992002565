/**
 * This file contains functions to manage cookies for tracking services
 *
 * The cookies from google (GA4) and Microsoft (Clarity) belong to Analysis category
 * The cookies from Facebook (FB Pixel) belong to Marketing category
 *
 * The cookies from FB and Microsoft stop doing post requests when the user revokes the consent,
 * so they work as they should. Therefore, the cookies can be deleted or not when the user revokes the consent.
 *
 * The cookies from GA4 keep doing post request when the user revokes the consent. It's unclear according to available
 * docs if they're sending anonymous data (which would be okey) or not (which would not comply with RGPD),
 * so we delete the cookies when the user revokes the consent.
 */

import ReactGA from 'react-ga4'
import { config } from '../constants'
import TagManager from 'react-gtm-module'

declare global {
  interface Window {
    fbq: (action: string, event: string) => void
  }
}

const cookiesEnabled = !(
  window.origin.includes('localhost') ||
  window.origin.includes('dev-web') ||
  window.origin.includes('alpha-web')
)

const domain = window.location.hostname.includes('localhost') ? 'localhost' : '.gemvision.com'

interface IRemoveCookie {
  (name: string, options?: { path: string; domain: string }): void
}

/**
 * Track a page event with Facebook Pixel
 */
export const trackPageEventFB = () => {
  window.fbq('track', 'PageView')
}

/**
 * Grant consent to track page with Facebook Pixel
 */
const grantConsentFB = () => {
  window.fbq('consent', 'grant')
  trackPageEventFB()
}

/**
 * Revoke consent to track page with Facebook Pixel
 */
const revokeConsentFB = () => {
  window.fbq('consent', 'revoke')
}

/**
 * Initialize GA4. Disabled by default. The consent must be granted to start tracking
 */
export const initializeGoogleAnalytics = () => {
  ReactGA.gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied'
  })
  ReactGA.initialize(config.GOOGLE_ANALYTICS_4_TRAKING_ID)
  const tagManagerArgs = {
    gtmId: config.GOOGLE_TAG_MANAGER
  }
  TagManager.initialize(tagManagerArgs)
}
/**
 * Grant consent to track page with Google Analytics
 */
const grantConsentGA = () => {
  ReactGA.gtag('consent', 'update', {
    analytics_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    ad_storage: 'granted'
  })
}

/**
 * Revoke consent to track page with Google Analytics
 */
const revokeConsentGA = () => {
  ReactGA.gtag('consent', 'update', {
    analytics_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    ad_storage: 'denied'
  })
}

/**
 * Delete GA cookies
 */
const deleteGACookies = (removeCookie: IRemoveCookie) => {
  const analyticsCookies = ['_ga', '_ga_FJKRRLFFFL', '_ga_1SD89TJ089']
  analyticsCookies.forEach((cookie) => {
    removeCookie(cookie, { domain, path: '/' })
  })
}

/**
 * Delete FB cookies
 */
const deleteFBCookies = (removeCookie: IRemoveCookie) => {
  removeCookie('_fbp', { domain, path: '/' })
}

/**
 * Update the status of GA Cookies
 */
export const updateGACookies = (enable: boolean, removeCookie: IRemoveCookie | null = null) => {
  if (enable) {
    grantConsentGA()
  } else {
    revokeConsentGA()
    if (removeCookie) {
      deleteGACookies(removeCookie)
    }
  }
}

/**
 * Update the status of FB Cookies
 */
export const updateFBCookies = (enable: boolean, removeCookie: IRemoveCookie | null = null) => {
  if (cookiesEnabled) {
    if (enable) {
      grantConsentFB()
    } else {
      revokeConsentFB()
      if (removeCookie) {
        deleteFBCookies(removeCookie)
      }
    }
  }
}
