import { GET_COUNTRIES_DATA } from '../actions'
import { IActionObject, ICountryWorld } from '../interface'

export const dataCountriesReducer = (state = { countries: [] }, action: IActionObject) => {
  switch (action.type) {
    case GET_COUNTRIES_DATA:
      const countries = action.payload
      countries.sort((a: ICountryWorld, b: ICountryWorld) =>
        a.DISPLAY > b.DISPLAY ? 1 : a.DISPLAY < b.DISPLAY ? -1 : 0
      )
      return { ...state, countries: countries }
    default:
      return state
  }
}
