import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const PendingSubscriptionPaymentPopUp = () => {
  const [open, setOpen] = useState(false)

  const setState = function () {
    setOpen(true)
  }

  useEffect(() => {
    window.addEventListener('showPendingSubscriptionPayment', setState)
    return () => {
      window.removeEventListener('showPendingSubscriptionPayment', setState)
    }
  }, [])

  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormattedMessage id='pendingPayment.title' />
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          <FormattedMessage id='pendingPayment.description1' />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id='pendingPayment.description2' />
        </Typography>
        <Typography>
          <FormattedMessage id='pendingPayment.description3' />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' to='/user-area/payment-method' component={Link} onClick={() => setOpen(false)}>
          <FormattedMessage id='pendingPayment.updatePaymentMethod' />
        </Button>
        <Button color='secondary' onClick={() => setOpen(false)}>
          <FormattedMessage id='pendingPayment.understand' />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PendingSubscriptionPaymentPopUp
