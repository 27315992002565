import React from 'react'
import './Footer.css'
import { Box, Stack } from '@mui/material'
import FooterMenu from './FooterMenu'
import FooterSocial from './FooterSocial'
import FooterLegal from './FooterLegal'
import FooterMenuMobile from './FooterMenuMobile'
import { ConfigContext } from '../../App'

export default function Footer() {
  const configContext = React.useContext(ConfigContext)
  return (
    <Box component='footer' bgcolor='#111' py={4} display='flex' justifyContent='center'>
      <Stack width='100%' maxWidth={1200} mx={{ xs: 2, sm: 4 }}>
        <FooterSocial />
        {configContext.isSmallScreen ? <FooterMenuMobile /> : <FooterMenu />}
        <FooterLegal />
      </Stack>
    </Box>
  )
}
