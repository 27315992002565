import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

export default function SimpleSpinner() {
  return (
    <Box
      sx={{
        height: '100vh',
        bgcolor: '#181818',
        position: 'relative'
      }}
    >
      <CircularProgress sx={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }} />
    </Box>
  )
}
