import {
  LOGOUT_ACCOUNT,
  STRIPE_ACCOUNT,
  GET_HISTORY_PAYMENT,
  DELETE_SUBSCRIPTION,
  SAVE_CARD,
  UPDATE_DEFAULT_CARD,
  DELETE_CARD
} from '../actions'

import { IActionObject, IStripe } from '../interface'

const initialState: IStripe = { stripeInfo: null, historyPayments: null, cardUserInfo: null }

interface ISourceData {
  brand: string
  country: string
  exp_month: number
  exp_year: number
  name: string
  object: string
  id: string
  last4: string
}

export default (state = initialState, action: IActionObject) => {
  let newUserInfo = null
  switch (action.type) {
    case STRIPE_ACCOUNT:
      if (action.payload.data !== 'no data') {
        action.payload.data.sources.data = action.payload.data.sources.data.filter(
          (item: ISourceData) => item.object === 'card'
        )
        return { ...state, stripeInfo: action.payload.data }
      }
      return { ...state }

    case LOGOUT_ACCOUNT:
      return { ...initialState }

    case GET_HISTORY_PAYMENT:
      if (action.payload.data) return { ...state, historyPayments: action.payload.data.data }
      else return state

    case DELETE_SUBSCRIPTION:
      newUserInfo = { ...state.stripeInfo }
      const subscriptionId = action.payload.data.id
      if (newUserInfo.subscriptions) {
        newUserInfo.subscriptions = newUserInfo.subscriptions.map((i: { id: string }) =>
          i.id !== subscriptionId ? i : action.payload.data
        )
      }
      return { ...state, stripeInfo: newUserInfo }

    case SAVE_CARD:
      newUserInfo = { ...state.stripeInfo }
      if (newUserInfo.sources) {
        newUserInfo.sources.data = [action.payload, ...newUserInfo.sources.data]
        newUserInfo.default_source = action.payload.id
      }
      return { ...state, stripeInfo: newUserInfo }

    case UPDATE_DEFAULT_CARD:
      newUserInfo = { ...state.stripeInfo }
      newUserInfo.default_source = action.payload.default_source
      return { ...state, stripeInfo: newUserInfo }

    case DELETE_CARD:
      newUserInfo = { ...state.stripeInfo }
      if (newUserInfo.sources) {
        newUserInfo.sources.data = newUserInfo.sources.data.filter((item: ISourceData) => item.id !== action.payload.id)
        if (newUserInfo.default_source === action.payload.id) {
          newUserInfo.default_source = null
        }
      }
      return { ...state, stripeInfo: newUserInfo }

    default:
      return state
  }
}
