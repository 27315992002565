import { SAVE_ROOT_BEFORE_LOGIN } from '../actions'

export default (state = { lastHistoryAvailable: '/' }, action: { root: string; type: string }) => {
  switch (action.type) {
    case SAVE_ROOT_BEFORE_LOGIN:
      return { ...state, lastHistoryAvailable: action.root }
    default:
      return state
  }
}
