import { LOGIN_ACCOUNT, LOGOUT_ACCOUNT, LOGGINGIN, PENDING_PAYMENTS, IS_AUTH } from '../actions'
import { IActionObject } from '../interface'

const initialState = {
  isAuthenticated: false,
  userInfo: null,
  loggingin: false,
  hasPendingPayments: false,
  pendingPayments: []
}

export default (state = initialState, action: IActionObject) => {
  switch (action.type) {
    case LOGIN_ACCOUNT:
      return {
        ...state,
        userInfo: action.payload,
        loggingin: false,
        stullerAccountObj: {
          BillToAccount: action.payload.BillToAccount,
          DefaultShipToAccount: action.payload.DefaultShipToAccount,
          ShipToAccountsHash: action.payload.ShipToAccountsHash,
          ShipToAccounts: action.payload.ShipToAccounts
        }
      }
    case PENDING_PAYMENTS:
      return {
        ...state,
        hasPendingPayments: action.payload.length !== 0,
        pendingPayments: action.payload
      }
    case LOGOUT_ACCOUNT:
      return { ...initialState }

    case LOGGINGIN:
      return { ...state, loggingin: action.payload }

    case IS_AUTH:
      return { ...state, isAuthenticated: true }

    default:
      return state
  }
}
