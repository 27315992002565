import React from 'react'
import { FormattedMessage } from 'react-intl'

export default function (values: any) {
  const errors: any = {}
  const requiredFields = ['name', 'email', 'phone', 'acceptTerms']
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = <FormattedMessage id='form.required' />
    }
  })

  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = <FormattedMessage id='form.invalid_email' />
  }

  return errors
}
