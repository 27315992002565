import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { IReducer } from './interface'

export default function ProtectedRoute(props: any) {
  const accountReducer = useSelector((state: IReducer) => state.accountReducer)
  const { isAuthenticated } = accountReducer
  if (!isAuthenticated) {
    return <Navigate to='/login' replace />
  }
  return props.children
}
