import { GET_EDUCATIONAL_PARTNERS_DATA } from '../actions'
import { IEducationalPartnerReducer } from '../interface'

interface IActionEducationalPartners {
  type: string
  payload: IEducationalPartnerReducer[]
}

export const educationalPartnersReducer = (state = null, action: IActionEducationalPartners) => {
  switch (action.type) {
    case GET_EDUCATIONAL_PARTNERS_DATA:
      return action.payload
    default:
      return state
  }
}
