import { Store } from 'react-notifications-component'

type TypeNotification = {
  type: 'success' | 'danger' | 'info' | 'default' | 'warning'
  message: any
}

export const sendNotification = ({ type, message }: TypeNotification) => {
  Store.addNotification({
    message,
    type,
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  })
}
