import React, { Fragment } from 'react'
import { Button, Fade, Popper, IconButton, Box, Stack } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { IReducer } from '../../interface'
import { logoutAccount } from '../../actions'
import { FormattedMessage } from 'react-intl'
import '../Nav/NavBar.css'
import { useCookies } from 'react-cookie'
import { ArrowDropDownRounded, PersonOutlineOutlined } from '@mui/icons-material'
import ProfilePicture from './ProfilePicture'
import { PaperDropdown } from '../../styles'

const Profile = () => {
  const [, , removeCookies] = useCookies(['country_code', 'temporary_user_id'])
  const navigate = useNavigate()
  const accountReducer = useSelector((state: IReducer) => state.accountReducer)
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    removeCookies('country_code', { path: '/' })
    removeCookies('temporary_user_id', { path: '/' })
    dispatch(logoutAccount())
    navigate('/')
  }

  return (
    <Fragment>
      {accountReducer.isAuthenticated === true ? (
        <Fragment>
          <Button
            disableTouchRipple
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            onMouseOver={handleOpen}
            onMouseOut={handleClose}
            data-cy='userArea-button-container'
            className={`nav-btn nav-btn-login ${open && 'nav-dropdown-selected'}`}
            sx={{
              borderRadius: 2,
              height: 48,
              minWidth: 32,
              pl: 1,
              pr: 1,
              textTransform: 'capitalize'
            }}
            endIcon={
              <ArrowDropDownRounded
                className='dropdown-arrow'
                sx={{ ml: -1, transform: open ? 'rotate(180deg)' : 'none' }}
              />
            }
          >
            <ProfilePicture firstName={accountReducer.userInfo.FirstName} />
            <Box
              sx={{
                fontSize: 13,
                display: { xs: 'none', sm: 'block' },
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 120
              }}
            >
              {accountReducer.userInfo.FirstName}
            </Box>
          </Button>
          <Popper
            onMouseOver={handleOpen}
            onMouseOut={handleClose}
            open={open}
            anchorEl={anchorRef.current}
            transition
            disablePortal
            placement='bottom-end'
          >
            {({ TransitionProps, placement }) => (
              <Fade {...TransitionProps} style={{ transformOrigin: placement }}>
                <PaperDropdown>
                  <Stack>
                    <Button
                      disableTouchRipple
                      className='nav-dropdown'
                      onClick={handleClose}
                      component={Link}
                      to='/user-area'
                      data-cy='userArea-button'
                    >
                      <FormattedMessage id='account.userArea' />
                    </Button>
                    <Button
                      disableTouchRipple
                      className='nav-dropdown'
                      onClick={() => {
                        handleClose()
                        handleLogout()
                      }}
                    >
                      <FormattedMessage id='account.logOut' />
                    </Button>
                  </Stack>
                </PaperDropdown>
              </Fade>
            )}
          </Popper>
        </Fragment>
      ) : (
        <IconButton color='secondary' className='nav-btn' disableTouchRipple component={Link} to='/login'>
          <PersonOutlineOutlined />
        </IconButton>
      )}
    </Fragment>
  )
}

export default Profile
