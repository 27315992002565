import React from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import { Dispatch } from 'redux'
import { BASE_URL_STRIPE } from '../constants'
import { PENDING_PAYMENTS, STRIPE_ACCOUNT } from './'

// --- Actions Type ---
export const GET_HISTORY_PAYMENT = 'GET_HISTORY_PAYMENT'
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION'
export const SAVE_CARD = 'SAVE_CARD'
export const UPDATE_DEFAULT_CARD = 'UPDATE_DEFAULT_CARD'
export const DELETE_CARD = 'DELETE_CARD'
export const GET_EDUCATION_PRODUCTS = 'GET_EDUCATION_PRODUCTS'
export const GET_MEMBERSHIP_PRODUCTS = 'GET_MEMBERSHIP_PRODUCTS'
export const GET_EDUCATION_PRODUCTS_FAILURE = 'GET_EDUCATION_PRODUCTS_FAILURE'

// --- Actions ---
export const getStripeAccount = (email: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const request = await axios.get(`${BASE_URL_STRIPE}/customer`, {
        params: {
          email
        }
      })
      dispatch({
        type: STRIPE_ACCOUNT,
        payload: request
      })
    } catch (error) {
      console.log('Error on getStripeAccount - ', error)
    }
    return Promise.resolve()
  }
}

export const getHistoryPayments = (customerId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const request = await axios.get(`${BASE_URL_STRIPE}/invoices`, {
        params: {
          customerId
        }
      })
      dispatch({
        type: GET_HISTORY_PAYMENT,
        payload: request
      })
    } catch (error) {
      console.error('Error on getHistoryPayments - ', error)
    }
  }
}

export const chargeSubscription = (invoicesIds: string[]) => {
  return async (dispatch: Dispatch) => {
    try {
      const chargePromises = []
      for (const invoiceId of invoicesIds) {
        chargePromises.push(
          axios.put(`${BASE_URL_STRIPE}/subscription`, {
            invoiceId,
            isCharge: true
          })
        )
      }
      await Promise.all(chargePromises)

      dispatch({
        type: PENDING_PAYMENTS,
        payload: []
      })
    } catch (error) {
      console.error('Error on chargeSubscription. Error:', error)
    }
  }
}

export const cancelSubscription = (subscriptionId: string, customerId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const request = await axios.delete(`${BASE_URL_STRIPE}/subscription`, {
        data: {
          subscriptionId,
          customerId
        }
      })
      dispatch({
        type: DELETE_SUBSCRIPTION,
        payload: request
      })
      window.dispatchEvent(
        new CustomEvent('sendNotification', {
          detail: { type: 'success', message: <FormattedMessage id='alert.subscriptionDeletedSuccess' /> }
        })
      )
    } catch (error) {
      console.error('Error on cancelSubscription - ', error)
      window.dispatchEvent(
        new CustomEvent('sendNotification', {
          detail: { type: 'danger', message: <FormattedMessage id='alert.subscriptionDeletedError' /> }
        })
      )
    }
  }
}

export const saveCreditCard = (customerId: string, tokenCard: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(`${BASE_URL_STRIPE}/cards`, {
        customerId,
        stripeToken: tokenCard
      })
      dispatch({
        type: SAVE_CARD,
        payload: response.data
      })
      window.dispatchEvent(
        new CustomEvent('sendNotification', {
          detail: { type: 'success', message: <FormattedMessage id='alert.cardSavedSuccess' /> }
        })
      )
    } catch (error: any) {
      console.error('Error on saveCreditCard - ', error)
      const message = error?.response?.data?.raw?.message

      window.dispatchEvent(
        new CustomEvent('sendNotification', {
          detail: {
            type: 'danger',
            message: message ? message : <FormattedMessage id={'alert.cardSavedError'} />
          }
        })
      )
    }
    return Promise.resolve()
  }
}

export const updateDefaultCard = (customerId: string, sourceId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.put(`${BASE_URL_STRIPE}/customer`, {
        customerId,
        default_source: sourceId
      })
      dispatch({
        type: UPDATE_DEFAULT_CARD,
        payload: response.data
      })
      window.dispatchEvent(
        new CustomEvent('sendNotification', {
          detail: { type: 'success', message: <FormattedMessage id='alert.updateDefaultCard' /> }
        })
      )
      return Promise.resolve()
    } catch (error: any) {
      window.dispatchEvent(
        new CustomEvent('sendNotification', {
          detail: { type: 'danger', message: <FormattedMessage id='alert.updateDefaultCardError' /> }
        })
      )
      return Promise.reject(error.response)
    }
  }
}

export const deleteCreditCard = (customerId: string, sourceId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.delete(`${BASE_URL_STRIPE}/cards`, {
        data: {
          customerId,
          sourceId
        }
      })
      dispatch({
        type: DELETE_CARD,
        payload: response.data
      })
      window.dispatchEvent(
        new CustomEvent('sendNotification', {
          detail: { type: 'success', message: <FormattedMessage id='alert.cardRemoved' /> }
        })
      )
    } catch (error: any) {
      console.error('Error on deleteCreditCard - ', error.response)
      window.dispatchEvent(
        new CustomEvent('sendNotification', {
          detail: { type: 'danger', message: <FormattedMessage id='alert.cardRemovedError' /> }
        })
      )
    }
    return Promise.resolve()
  }
}

export const getStripeProductsBySKU = (countryCode: string, priceSKUs: string[]) => {
  return async (dispatch: Dispatch) => {
    try {
      const request = await axios.get(`${BASE_URL_STRIPE}/productsBySKU`, {
        params: {
          countryCode,
          priceSKUs: JSON.stringify(priceSKUs)
        }
      })
      dispatch({
        type: GET_MEMBERSHIP_PRODUCTS,
        payload: request
      })
    } catch (error) {
      console.error('Error on getStripeProductsBySKU - ', error)
    }
    return Promise.resolve()
  }
}

export const getEducationProducts = (countryCode: string, category: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const request = await axios.get(`${BASE_URL_STRIPE}/educationProducts`, {
        params: {
          countryCode,
          category
        }
      })
      dispatch({
        type: GET_EDUCATION_PRODUCTS,
        payload: { data: request.data, countryCode }
      })
    } catch (error) {
      console.error('Error on getEducationProducts - ', error)
      window.dispatchEvent(
        new CustomEvent('sendNotification', {
          detail: { type: 'danger', message: <FormattedMessage id='alert.fetchProductsError' /> }
        })
      )
      dispatch({
        type: GET_EDUCATION_PRODUCTS_FAILURE
      })
    }
    return Promise.resolve()
  }
}
