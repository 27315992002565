import React, { Fragment } from 'react'
import { TextField } from '@mui/material'
import { FieldRenderProps } from 'react-final-form'

const TextFieldReduxForm = (params: FieldRenderProps<string>) => {
  return (
    <Fragment>
      <TextField
        disabled={params.disabled}
        label={params.title}
        placeholder={params.title}
        error={params.meta.touched && params.meta.invalid}
        helperText={params.meta.touched && params.meta.error}
        data-cy={params.dataCy}
        {...params.input}
      />
    </Fragment>
  )
}

export default TextFieldReduxForm
