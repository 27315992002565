import { GET_BANNERS_DATA } from '../actions'
import { IBannerReducer } from '../interface'

interface IActionBanner {
  type: string
  payload: IBannerReducer[]
}

export const dataBannersReducer = (state = null, action: IActionBanner) => {
  switch (action.type) {
    case GET_BANNERS_DATA:
      return action.payload
    default:
      return state
  }
}
