import { ACTIVE_MODAL, DISACTIVE_MODAL } from '../actions'
import { IActionObject } from '../interface'

const initialState = {
  url: undefined
}

export default (state = initialState, action: IActionObject) => {
  switch (action.type) {
    case ACTIVE_MODAL:
      return { ...state, url: action.payload }
    case DISACTIVE_MODAL:
      return { ...state, url: undefined }

    default:
      return state
  }
}
