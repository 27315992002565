import SPANISH_STATE from '../resources/es.json'
import ENGLISH_STATE from '../resources/en.json'

type fn = () => void | any

const esLanguage = {
  lang: 'es',
  messages: SPANISH_STATE
}
const enLanguage = {
  lang: 'en',
  messages: ENGLISH_STATE
}
export const detectLanguage: fn = () => {
  const language = (navigator.languages && navigator.languages[0]) || navigator.language
  let initialState = null
  if (language.includes('es')) {
    initialState = esLanguage
  } else if (language.includes('en')) {
    initialState = enLanguage
  } else {
    initialState = enLanguage
  }
  return initialState
}
