import { IconButton, Modal, Box } from '@mui/material'
import { Close } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import ReactPlayer from 'react-player'
import { useDispatch, useSelector } from 'react-redux'
import { IReducer } from '../interface'
import { desactiveModal } from '../actions'

const CloseButton = styled(IconButton)({
  opacity: 0.75,
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 100,
  transition: 'opacity .3s ease',
  '&:hover': {
    opacity: 1
  }
})

const style = {
  position: 'fixed' as 'fixed',
  inset: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 20
}

const VideoModal = () => {
  const dispatch = useDispatch()
  const url = useSelector((state: IReducer) => state.modalReducer.url)
  const handleClose = () => dispatch(desactiveModal())

  return (
    <Modal sx={{ backgroundColor: '#111e' }} open={!!url} onClose={handleClose}>
      <Box style={style} onClick={handleClose}>
        <CloseButton size='large' onClick={handleClose}>
          <Close color='secondary' />
        </CloseButton>
        <ReactPlayer width={1024} height={576} url={url} playing={true} controls={true} />
      </Box>
    </Modal>
  )
}

export default VideoModal
