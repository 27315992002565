import { GET_EDUCATION_PRODUCTS, GET_MEMBERSHIP_PRODUCTS, GET_EDUCATION_PRODUCTS_FAILURE } from '../actions'
import { IStripeInitialState, IStripeProduct } from '../interface'

interface IActionStripeProducts {
  type: string
  payload: {
    category: string
    data: IStripeProduct[]
    countryCode: string
  }
}

const initialState: IStripeInitialState = {
  educationProducts: null,
  membershipProducts: null,
  countryCode: null,
  error: false
}

export const stripeProductsReducer = (state = initialState, action: IActionStripeProducts) => {
  switch (action.type) {
    case GET_EDUCATION_PRODUCTS:
      const educationProductsList: IStripeProduct[] = []
      const products = action.payload.data
      if (state.countryCode !== action.payload.countryCode) {
        state.educationProducts = []
      }
      products.forEach((product) => {
        educationProductsList.push(product)
      })

      return {
        ...state,
        educationProducts: [...(state.educationProducts ?? []), ...educationProductsList],
        countryCode: action.payload.countryCode,
        error: false
      }
    case GET_MEMBERSHIP_PRODUCTS:
      return {
        ...state,
        membershipProducts: action.payload.data,
        error: false
      }
    case GET_EDUCATION_PRODUCTS_FAILURE:
      return {
        ...state,
        error: true
      }
    default:
      return state
  }
}
