import { gql, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { BASE_URL_GRAPHQL } from '../constants'

// Note: When whole backend has been done with apollo, we'll could change "REACT_APP_ENDPOINT" env to "http://localhost:4000"
const uri = process.env.REACT_APP_is_local_apollo ? 'http://localhost:4000' : BASE_URL_GRAPHQL
const client = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link: new HttpLink({ uri })
})

// ### Queries ###
const GET_BANNERS = gql`
  query GetBanners($dateNow: Float!) {
    getBanners(dateNow: $dateNow) {
      sort
      background
      backgroundColor
      buttonOptions {
        color
        text
        variant
      }
      contentOptions {
        color
        variant
      }
      contentText
      creation_date
      end_date
      link
      start_date
      title
      titleOptions {
        color
        variant
      }
      type
    }
  }
`
export { client, GET_BANNERS }
