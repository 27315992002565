import React, { useEffect } from 'react'

function MicroFrontend({ name, host }) {
  useEffect(() => {
    const scriptId = `micro-frontend-script-${name}`
    const cssId = `micro-frontend-css-${name}`

    const renderMicroFrontend = () => {
      if (window[`render${name}`]) {
        window[`render${name}`](`${name}-container`)
      }
    }

    if (document.getElementById(scriptId) && document.getElementById(cssId)) {
      renderMicroFrontend()
    } else {
      // fetch(`${host}/asset-manifest.json`)
      //   .then((res) => res.json())
      //   .then((manifest) => {
      //     console.log('cssId', cssId)
      //     console.log(host)

      // console.log(host, manifest.files["main.css"])
      //CSS
      const link = document.createElement('link')
      link.id = cssId
      link.rel = 'stylesheet'
      link.type = 'text/css'
      // link.as ='style';
      link.href = `${host}/static/css/main.css`
      //JS
      const script = document.createElement('script')
      script.id = scriptId
      // script.rel = 'preload';
      script.crossOrigin = ''
      // script.as= 'script'
      script.src = `${host}/static/js/main.js`
      script.onload = () => {
        renderMicroFrontend()
      }
      document.head.appendChild(script)
      document.head.appendChild(link)
    }
    return () => {
      window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <main id={`${name}-container`} />
}

MicroFrontend.defaultProps = {
  document,
  window
}

export default MicroFrontend
